import { useState, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useAuthContext } from 'src/auth/hooks';
import { useNavigate, useLocation  } from 'react-router-dom';
import { HEADER } from '../config-layout';


// ----------------------------------------------------------------------

type Props = {
    onOpenNav?: VoidFunction;
};

export default function Footer({ onOpenNav }: Props) {
    const theme = useTheme();
    const { user } = useAuthContext(); // Get user details
    const navigate = useNavigate(); // For navigation
    const isMobile = useResponsive('down', 'md'); // Check if the device is mobile (md and down)

     // State for active icon
     const [activeIcon, setActiveIcon] = useState<string>(''); // Can use 'agent', 'shop', etc.

     const location = useLocation();
 
     // Effect to set the active icon based on the current path
     useEffect(() => {
         const path = location.pathname;
         if (path.includes('operator/create')) {
             setActiveIcon('agent');
         } else if (path.includes('shop/create')) {
             setActiveIcon('shop');
         } else if (path.includes('dashboard/transaction')) {
             setActiveIcon('transactions');
         } else if (path.includes('dashboard/gamestatics')) {
             setActiveIcon('reports');
         } else if (path.includes('profile')) {
             setActiveIcon('profile');
         } else {
             setActiveIcon(''); // Reset active icon for other pages
         }
     }, [location.pathname]);

    // Handlers for navigation actions
    const handleNewAgent = () => navigate('/operator/create');
    const handleNewShop = () => navigate('/shop/create');
    const handleTransactions = () => navigate('/dashboard/transaction');
    const handleReports = () => navigate('/dashboard/gamestatics');
    const handleProfile = () => navigate('/profile');

    // Render nothing if not on mobile
    if (!isMobile) {
        return null;
    }

    return (
        <AppBar
            position="fixed"
            sx={{
                top: 'auto',
                bottom: 0,
                height: HEADER.H_MOBILE, // Adjust as needed
                bgcolor: theme.palette.background.paper,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                boxShadow: '0px 0px 20px 0px rgba(162, 156, 156, 0.30)', 
              
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems:'stretch',
                    px:0
                }}
            >
                <IconButton onClick={handleNewAgent} sx={{
                    display:'flex',
                    flexFlow:'column wrap',
                    color: activeIcon === 'agent' ? '#171C23' : '#868687',
                    borderRadius:0,
                    borderTop:"3px solid",
                    borderColor: activeIcon === 'agent' ? '#171C23' : '#FFFFFF',
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M19.9999 19.5373C21.6575 19.5373 23.2472 20.1958 24.4193 21.3679C25.5914 22.54 26.2499 24.1297 26.2499 25.7873V27.0373C26.2499 27.7003 25.9865 28.3362 25.5177 28.8051C25.0489 29.2739 24.413 29.5373 23.7499 29.5373H6.24993C5.58689 29.5373 4.951 29.2739 4.48216 28.8051C4.01332 28.3362 3.74993 27.7003 3.74993 27.0373V25.7873C3.74993 24.1297 4.40841 22.54 5.58051 21.3679C6.75261 20.1958 8.34232 19.5373 9.99993 19.5373H19.9999ZM24.9999 12.0373C25.3314 12.0373 25.6494 12.169 25.8838 12.4034C26.1182 12.6378 26.2499 12.9558 26.2499 13.2873V14.5373H27.4999C27.8314 14.5373 28.1494 14.669 28.3838 14.9034C28.6182 15.1378 28.7499 15.4558 28.7499 15.7873C28.7499 16.1188 28.6182 16.4368 28.3838 16.6712C28.1494 16.9056 27.8314 17.0373 27.4999 17.0373H26.2499V18.2873C26.2499 18.6188 26.1182 18.9368 25.8838 19.1712C25.6494 19.4056 25.3314 19.5373 24.9999 19.5373C24.6684 19.5373 24.3505 19.4056 24.116 19.1712C23.8816 18.9368 23.7499 18.6188 23.7499 18.2873V17.0373H22.4999C22.1684 17.0373 21.8505 16.9056 21.616 16.6712C21.3816 16.4368 21.2499 16.1188 21.2499 15.7873C21.2499 15.4558 21.3816 15.1378 21.616 14.9034C21.8505 14.669 22.1684 14.5373 22.4999 14.5373H23.7499V13.2873C23.7499 12.9558 23.8816 12.6378 24.116 12.4034C24.3505 12.169 24.6684 12.0373 24.9999 12.0373ZM14.9999 4.53729C16.6575 4.53729 18.2472 5.19577 19.4193 6.36788C20.5914 7.53998 21.2499 9.12969 21.2499 10.7873C21.2499 12.4449 20.5914 14.0346 19.4193 15.2067C18.2472 16.3788 16.6575 17.0373 14.9999 17.0373C13.3423 17.0373 11.7526 16.3788 10.5805 15.2067C9.40841 14.0346 8.74993 12.4449 8.74993 10.7873C8.74993 9.12969 9.40841 7.53998 10.5805 6.36788C11.7526 5.19577 13.3423 4.53729 14.9999 4.53729Z" fill="currentColor" />
                    </svg>
                    <Typography variant="caption" fontSize={10}>New Agent</Typography>
                </IconButton>

                <IconButton onClick={handleNewShop} sx={{
                    display: 'flex',
                    flexFlow: 'column wrap',
                    color: activeIcon === 'shop' ? '#171C23' : '#868687',
                    borderRadius: 0,
                    borderTop: "3px solid",
                    borderColor: activeIcon === 'shop' ? '#171C23' : '#FFFFFF',
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 30" width="30" height="30">
                        <g id="Layer_1">
                            <path d="M5.7,4.9c.2-.9.3-1.4.7-1.6.3-.3.8-.3,1.8-.3h13.7c.9,0,1.4,0,1.8.3.3.3.5.7.7,1.6l1.5,6.1c.1.5.2.7.2.9,0,.3-.1.5-.3.7-.2.2-.4.3-.6.4-.2,0-.4,0-1,0s-1,0-1.3,0c-.4,0-.8-.3-1.1-.5-.3-.3-.5-.6-.7-1-.1-.3-.2-.6-.3-1.2,0-.2,0-.3,0-.3,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0,0,0,0,.1,0,.3v.6c-.1,0-.1.1-.1.1-.1.6-.4,1.1-.8,1.5-.4.4-1,.6-1.6.6h-.3c-.6,0-1.2-.2-1.6-.6-.4-.4-.7-.9-.8-1.5v-.6c-.1-.2-.1-.3-.2-.3,0,0,0,0,0,0s0,0,0,0,0,0,0,0,0,0,0,0c0,0,0,.1,0,.3v.6c-.1,0-.1.1-.1.1-.1.6-.4,1.1-.8,1.5-.4.4-1,.6-1.6.6h-.3c-.6,0-1.1-.2-1.6-.6-.4-.4-.7-.9-.8-1.5h0c0-.1-.1-.7-.1-.7,0-.2,0-.3,0-.3,0,0,0,0,0,0s0,0,0,0,0,0,0,0,0,0,0,0c0,0,0,.1,0,.3-.1.6-.2,1-.3,1.2-.2.4-.4.7-.7,1-.3.3-.7.4-1.1.5-.3,0-.6,0-1.3,0s-.8,0-1,0c-.2,0-.5-.2-.6-.4-.2-.2-.2-.4-.3-.7,0-.2,0-.4.2-.9l1.5-6.1ZM6.2,15c0,.5,0,1.2,0,1.9v7.6c0,1.2,0,1.8.4,2.2.4.4,1,.4,2.2.4h3.8v-5c0-.3.1-.7.4-.9.2-.2.6-.4.9-.4h2.5c.3,0,.7.1.9.4s.4.6.4.9v5h3.8c1.2,0,1.8,0,2.2-.4s.4-1,.4-2.2v-7.6c0-.7,0-1.4,0-1.9-.5,0-.9,0-1.4-.1-.6-.2-1.2-.4-1.8-.9-.7.6-1.7,1-2.7,1h-.2s-.2,0-.2,0c-1,0-1.9-.4-2.7-1-.7.6-1.7,1-2.7,1h-.2s-.2,0-.2,0c-1,0-1.9-.4-2.7-1-.5.4-1.1.7-1.8.9-.4.1-.9.1-1.4.1" fill="currentColor" />
                            <rect width="30" height="30" fill="none" />
                        </g>
                    </svg>
                    <Typography variant="caption" fontSize={10}>New Shop</Typography>
                </IconButton>

                <IconButton onClick={handleTransactions} sx={{
                    display: 'flex',
                    flexFlow: 'column wrap',
                    color: activeIcon === 'transactions' ? '#171C23' : '#868687',
                    borderRadius: 0,
                    borderTop: "3px solid",
                    borderColor: activeIcon === 'transactions' ? '#171C23' : '#FFFFFF',
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30">
                        <path d="M15,28c-1.5,0-2.9-.2-4.2-.7h0c-5.2-1.8-8.8-6.7-8.8-12.3,0-2.9,1-5.7,2.8-8,.3-.4,1-.5,1.4-.2.4.3.5,1,.2,1.4-1.5,2-2.3,4.3-2.3,6.8,0,4.7,3,8.9,7.4,10.4,0,0,0,0,0,0,1.1.4,2.4.6,3.6.6,6.1,0,11-4.9,11-11h0c0-6.1-5-11-11-11h0c-1.2,0-2.5.2-3.6.6-.5.2-1.1,0-1.3-.6-.2-.5,0-1.1.6-1.3,1.4-.5,2.8-.7,4.3-.7h0c7.1,0,13,5.8,13,12.9h0c0,7.2-5.8,13.1-13,13.1ZM22,16c.6,0,1,.4,1,1s-.4.9-.9,1h-.1s-11.6,0-11.6,0l3.3,3.3c.4.4.4.9,0,1.3h0c-.4.5-.9.5-1.3.2h0c0,0-5-5.1-5-5.1-.6-.6-.2-1.6.6-1.7h.1s14,0,14,0ZM17.6,7.2h0c0,0,5,5.1,5,5.1.6.6.2,1.6-.6,1.7h-.1s-14,0-14,0c-.6,0-1-.4-1-1s.4-.9.9-1h.1s11.6,0,11.6,0l-3.3-3.3c-.4-.4-.4-.9,0-1.3h0c.4-.5.9-.5,1.3-.2Z" fill="currentColor" />
                        <rect width="30" height="30" fill="none" />
                    </svg>
                    <Typography variant="caption" fontSize={10}>Transactions</Typography>
                </IconButton>

                <IconButton onClick={handleReports} sx={{
                    display: 'flex',
                    flexFlow: 'column wrap',
                    color: activeIcon === 'reports' ? '#171C23' : '#868687',
                    borderRadius: 0,
                    borderTop: "3px solid",
                    borderColor: activeIcon === 'reports' ? '#171C23' : '#FFFFFF',
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30">
                        <path d="M26,26H4v-2.4h22v2.4ZM10.1,12.6h-4.9v8.6h4.9v-8.6ZM17.4,7.7h-4.9v13.4h4.9V7.7ZM24.8,4h-4.9v17.1h4.9V4Z" fill="currentColor" />
                        <rect width="30" height="30" fill="none" />
                    </svg>
                    <Typography variant="caption" fontSize={10}>Reports</Typography>
                </IconButton>

                <IconButton onClick={handleProfile} sx={{
                    display: 'flex',
                    flexFlow: 'column wrap',
                    color: activeIcon === 'profile' ? '#171C23' : '#868687',
                    borderRadius: 0,
                    borderTop: "3px solid",
                    borderColor: activeIcon === 'profile' ? '#171C23' : '#FFFFFF',
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30">
                        <path d="M15,4c-6.1,0-11,4.9-11,11s4.9,11,11,11,11-4.9,11-11S21.1,4,15,4ZM15,8.4c2.1,0,3.9,1.7,3.9,3.9s-1.7,3.9-3.9,3.9-3.9-1.7-3.9-3.9,1.7-3.9,3.9-3.9ZM15,23.8c-2.2,0-4.9-.9-6.8-3.2,1.9-1.5,4.3-2.3,6.8-2.3s4.8.8,6.8,2.3c-1.9,2.3-4.5,3.2-6.8,3.2Z" fill="currentColor" />
                        <rect width="30" height="30" fill="none" />
                    </svg>
                    <Typography variant="caption" fontSize={10}>Profile</Typography>
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}