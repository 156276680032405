import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { useRouter } from 'src/routes/hooks';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

const OPTIONS = [
    {
        label: 'Home',
        linkTo: '/'
    },
    {
        label: 'Profile',
        linkTo: '/profile'
    }
    // {
    //     label: 'Settings',
    //     linkTo: '/#2'
    // }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const router = useRouter();

    const { user } = useAuthContext();

    const { logout } = useAuthContext();

    const popover = usePopover();

    const handleLogout = async () => {
        try {
            await logout();
            popover.onClose();
            router.replace('/');
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickItem = (path: string) => {
        popover.onClose();
        router.push(path);
    };

    return (
        <>
            <IconButton
                component={m.button}
                // whileTap="tap"
                // whileHover="hover"
                // variants={varHover(1.05)}
                onClick={popover.onOpen}
                disableRipple
                disableFocusRipple
                sx={{
                    // width: 40,
                    // height: 40,
                    bgcolor: '#171C23',
                    padding: '8px',
                    borderRadius: '50px',
                    // background: (theme) => alpha(theme.palette.grey[500], 0.08),
                    // ...(popover.open && {
                    //     background: (theme) =>
                    //         `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`
                    // })
                    transition: 'background-color 0.3s',  // Smooth transition for hover effect
                    '&:hover': {
                        bgcolor: '#171C23 !important', // Force background to be transparent on hover
                    },
                   
                }}

            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(255,255,255,1)" width="20" height="20"><path d="M20 22H4V20C4 17.2386 6.23858 15 9 15H15C17.7614 15 20 17.2386 20 20V22ZM12 13C8.68629 13 6 10.3137 6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7C18 10.3137 15.3137 13 12 13Z"/></svg>
                {/* <Avatar
                    src={user?.avatar}
                    alt={user?.username}
                    sx={{
                        width: 36,
                        height: 36,
                        border: (theme) => `solid 2px ${theme.palette.background.default}`
                    }}
                >
                    {String(user?.firstName)
                        .charAt(0)
                        .toUpperCase()}
                </Avatar> */}
            </IconButton>

            <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
                <Box sx={{ p: 2, pb: 1.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.username}
                    </Typography>

                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    {OPTIONS.map((option) => (
                        <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}>
                    Logout
                </MenuItem>
            </CustomPopover>
        </>
    );
}
