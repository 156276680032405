// routes
import { paths } from 'src/routes/paths';

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//
import { useAuthContext } from 'src/auth/hooks';
import { AuthContext } from './auth/context/jwt/auth-context';


// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const AMPLIFY_API = {
    userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    region: process.env.REACT_APP_AWS_AMPLIFY_REGION
};

export const AUTH0_API = {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
// export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
export const PATH_AFTER_LOGIN = paths.operator.list; // as '/operator'
export const PATH_AFTER_LOGIN_SHOP = paths.user.index;

// export const PATH_AFTER_LOGIN = () => {
//     const { user } = useAuthContext();
//     const navigate = useNavigate();

    
//     if (!user) {
//         return paths.operator.list; 
//     }

//     const users= user.roleId === 'shop' 
//     ? paths.user.index 
//     : paths.operator.list; 
    
//     console.log(user.roleId, 'Role ID');
//     console.log(users, 'Redirecting to path');
//     navigate(users);
//     return users;

// }

// const user = {
//     roleId: 'shop', // This would typically come from your login response
//     // other user details...
// };

// export const PATH_AFTER_LOGIN = user.roleId === 'shop' ? paths.user.index : paths.operator.list;

// export const PATH_AFTER_LOGIN = () => {
//     const { user } = useContext(AuthContext);

//     if (user?.roleId === 'admin') {
//         return paths.operator.list;
//     }
//     if (user?.roleId === 'shop') {
//         return paths.user.index;
//     }
//     return '/';
// }
